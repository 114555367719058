import React from 'react';
import css from './Topbar.module.css';
import money from '../../../assets/money.png';

const OfferAlertBar = props => {
  return (
    <div className={css.offerAlertBar}>
      <img src={money} alt="money" className={css.moneyImage} />
      SHARE YOUR UNIQUE REFERRAL LINK WITH 3 PEOPLE AND EARN £100! YOU CAN REFER AS MANY PEOPLE AS
      YOU LIKE
    </div>
  );
};

export default OfferAlertBar;
